import React, { Suspense } from 'react';
import './App.css';
import Header from './components/Header';
import Home from './components/Home';
import { BrowserRouter, Route } from 'react-router-dom';
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';
const NewsletterEditor = React.lazy(() => import('./Grapes/Editor'));
const Mailer = React.lazy(() => import('./Mailer/Mailer'));

const oktaAuth = new OktaAuth({
	issuer: 'https://dev-95831200.okta.com/oauth2/default',
	clientId: '0oa7xiwdq5bEUuuUg5d6',
	redirectUri: window.location.origin + '/callback',
	scopes: ['profile', 'openid'],
});
function App() {
	return (
		<div className="App">
			<div className="page">
				<div className="content">
					<BrowserRouter>
						<Security oktaAuth={oktaAuth}>
							<Header />
							<Suspense fallback={<div>Loading...</div>}>
								<Route path="/" exact={true} component={Home} />
								<SecureRoute
									path="/mailer"
									exact={true}
									component={Mailer}
								/>
								<SecureRoute
									path="/editor/:slug"
									exact={true}
									component={NewsletterEditor}
								/>
								<SecureRoute
									path="/editor"
									exact={true}
									component={NewsletterEditor}
								/>
								<Route
									path="/callback"
									component={LoginCallback}
								/>
							</Suspense>
						</Security>
					</BrowserRouter>
				</div>
			</div>
		</div>
	);
}

export default App;

//  aws s3 rm s3://kerendavidmovers.tk --recursive && aws s3 cp ./dist s3://kerendavidmovers.tk --recursive --acl public-read
