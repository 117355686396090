import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Home() {
	return (
		<Container className="d-flex flex-column justify-content-around align-items-center">
			<h1>Login with React</h1>
			<Link to="/mailer">
				<Button type="button">Login</Button>
			</Link>
		</Container>
	);
}

export default Home;

//  aws s3 rm s3://kerendavidmovers.tk --recursive && aws s3 cp ./dist s3://kerendavidmovers.tk --recursive --acl public-read
