import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { Button, Nav, Navbar } from 'react-bootstrap';

function Header() {
	const { oktaAuth, authState } = useOktaAuth();
	const { isAuthenticated } = authState;
	const login = () => {
		oktaAuth.signInWithRedirect();
	};
	const logout = () => {
		oktaAuth.signOut();
	};

	return (
		<Navbar bg="dark" variant="dark" className="justify-content-between">
			<Navbar.Brand href="/mailer">
				<img
					alt=""
					src="/logo.svg"
					width="30"
					height="30"
					className="d-inline-block align-top"
				/>{' '}
				Yonni's Mailer App
			</Navbar.Brand>
			<Nav className="mr-auto">
				<Nav.Link as={Link} to="/mailer">
					Mailer
				</Nav.Link>
				<Nav.Link as={Link} to="/editor" className="ml-5">
					Editor
				</Nav.Link>
			</Nav>
			<Button onClick={isAuthenticated ? logout : login}>
				{isAuthenticated ? 'Logout' : 'Login'}
			</Button>
		</Navbar>
	);
}

export default Header;
